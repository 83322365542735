/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import { globalHistory } from '@reach/router';

function SEO({ description, lang, meta, title, image }) {
  const general = useStaticQuery(graphql`
    query seoQuery {
      med {
        pl: general(
          where: { id: "ckjzk9y6w2vbv0a069puimmzp" }
          locales: pl_PL
        ) {
          defaultSeoTitle
          defaultSeoDescription
          defaultSeoImage {
            url
          }
        }
        en: general(where: { id: "ckjzk9y6w2vbv0a069puimmzp" }, locales: en) {
          defaultSeoTitle
          defaultSeoDescription
          defaultSeoImage {
            url
          }
        }
      }
    }
  `);

  const path = globalHistory.location.pathname;
  const isEn =
    path.indexOf('/en/') > -1 || path.endsWith('/en/') || path.endsWith('/en');

  // TODO: add default values from CMS
  const defaultTitle = isEn
    ? general.med.en.defaultSeoTitle
    : general.med.pl.defaultSeoTitle;
  const defaultDescription = isEn
    ? general.med.en.defaultSeoDescription
    : general.med.pl.defaultSeoDescription;
  const defaultImage = isEn
    ? general.med.en.defaultSeoImage && general.med.en.defaultSeoImage.url
    : general.med.pl.defaultSeoImage && general.med.pl.defaultSeoImage.url;

  const metaTitle = title || defaultTitle;
  const metaImage = (image && image.url) || defaultImage;
  const metaDescription = description || defaultDescription;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    >
      <meta property="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content={'website'} />
      <meta property="og:image" content={metaImage} />
      <meta property="twitter:card" content={'summary_large_image'} />
      <meta property="twitter:creator" content={'Mediocre'} />
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:description" content={metaDescription} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${withPrefix('/')}favicon/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${withPrefix('/')}favicon/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${withPrefix('/')}favicon/favicon-16x16.png`}
      />
      <link
        rel="manifest"
        href={`${withPrefix('/')}favicon/site.webmanifest`}
      />
      <link
        rel="mask-icon"
        href={`${withPrefix('/')}favicon/safari-pinned-tab.svg`}
        color="#000000"
      />

      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="theme-color" content="#ffffff" />

    </Helmet>
  );
}

export default SEO;
